import { render, staticRenderFns } from "./contacts.vue?vue&type=template&id=b9839c94"
import script from "./contacts.vue?vue&type=script&lang=js"
export * from "./contacts.vue?vue&type=script&lang=js"
import style0 from "./contacts.vue?vue&type=style&index=0&id=b9839c94&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactsTable: require('/codebuild/output/src2108204121/src/web/components/contacts/contacts-table.vue').default})
