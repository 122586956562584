import { render, staticRenderFns } from "./cik-status-update-cell.vue?vue&type=template&id=3f6daddf&scoped=true"
import script from "./cik-status-update-cell.vue?vue&type=script&lang=js"
export * from "./cik-status-update-cell.vue?vue&type=script&lang=js"
import style0 from "./cik-status-update-cell.vue?vue&type=style&index=0&id=3f6daddf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f6daddf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2108204121/src/web/components/loading-spinner.vue').default})
